import { useEffect, useState } from 'react';
import './week-interval.styles.scss';

const WeekInterval = ({
  startHour,
  startMinute,
  endHour,
  endMinute,
  week,
  working,
  id,
  setValues,
}) => {
  const [textWeek, setTextWeek] = useState('');

  const setValue = (name, value) => {
    return setValues((prevValues) => {
      let newValues = { ...prevValues };
      newValues.intervals[id][name] = value;
      return newValues;
    });
  };

  const formatTime = (hour, minute) => {
    if (hour < 10) hour = '0' + hour;
    if (minute < 10) minute = '0' + minute;

    return `${hour}:${minute}`;
  };

  const defaultWeek = (week) => {
    return [...week].map((day, index) => {
      if (day === '1') return String(index);
    });
  };

  useEffect(() => {
    if (week === '0000000') return setTextWeek('NEVER');
    if (week === '1111111') return setTextWeek('EVERYDAY');
    if (week === '1111100') return setTextWeek('WORKWEEK');
    if (week === '0000011') return setTextWeek('WEEKEND');
    const srtWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    let stringWeek = '';
    [...week].forEach((value, index) => {
      if (value === '1') stringWeek += `${srtWeek[index]}, `;
    });
    stringWeek = stringWeek.slice(0, -2);
    setTextWeek(stringWeek);
  }, [week]);

  const onSelect = (event) => {
    let values = Array.from(event.target.selectedOptions, (option) =>
      Number(option.value)
    );
    let newWeek = '';
    for (let i = 0; i < 7; i++) {
      if (values.includes(i)) newWeek += '1';
      else newWeek += '0';
    }
    setValue('week', newWeek);
  };

  return (
    <div className={`week-interval ${working && 'working'}`}>
      <div className="week-interval__time">
        <input
          name="startTime"
          type="time"
          value={`${formatTime(startHour, startMinute)}`}
          onChange={(event) => {
            const time = event.target.value.split(':');
            setValue('startHour', Number(time[0]));
            setValue('startMinute', Number(time[1]));
          }}
        />
        <span> - </span>
        <input
          name="endTime"
          type="time"
          value={`${formatTime(endHour, endMinute)}`}
          onChange={(event) => {
            const time = event.target.value.split(':');
            setValue('endHour', Number(time[0]));
            setValue('endMinute', Number(time[1]));
          }}
        />
      </div>
      <select
        className="week-interval__select"
        name="week"
        id={`week${id}`}
        multiple
        onChange={onSelect}
        defaultValue={defaultWeek(week)}
      >
        <option value="0">Monday</option>
        <option value="1">Tuesday</option>
        <option value="2">Wednesday</option>
        <option value="3">Thursday</option>
        <option value="4">Friday</option>
        <option value="5">Saturday</option>
        <option value="6">Sunday</option>
      </select>
      <label className="week-interval__select-label" htmlFor={`week${id}`}>
        {textWeek}
      </label>
      <button
        className="week-interval__checkbox"
        onClick={() => setValue('working', !working)}
      ></button>
    </div>
  );
};

export default WeekInterval;
