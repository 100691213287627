import { Routes, Route } from 'react-router-dom';

import SettingsEmpty from '../../components/settings-empty/settings-empty.component';
import BottomMenu from '../../components/bottom-menu/bottom-menu.component';
import BottomMenuItem from '../../components/bottom-menu-item/bottom-menu-item.component';
import TallNumberSelect from '../../components/tall-number-input/tall-number-input.component';
import NumberSelect from '../../components/number-input/number-input.component';

import './settings.styles.scss';
import WeekInterval from '../../components/week-interval/week-interval.component';
import { ReactComponent as Force } from '../../assets/force.svg';
import { ReactComponent as Off } from '../../assets/off.svg';
import { ReactComponent as Schedule } from '../../assets/schedule.svg';

const Settings = ({ settings, setSettings }) => {
  return (
    <div className="settings__container">
      <Routes>
        <Route
          path="/"
          element={
            <BottomMenu>
              <BottomMenuItem
                Icon={Force}
                title="force"
                setValues={setSettings}
                active={settings.manner == 'force'}
              />
              <BottomMenuItem
                Icon={Off}
                title="off"
                setValues={setSettings}
                active={settings.manner == 'off'}
              />
              <BottomMenuItem
                Icon={Schedule}
                title="schedule"
                setValues={setSettings}
                active={settings.manner == 'schedule'}
              />
            </BottomMenu>
          }
        >
          <Route
            path="force"
            element={
              <>
                <TallNumberSelect
                  name="forceT"
                  title="Force temp."
                  max={35}
                  value={settings.forceT}
                  setValues={setSettings}
                />
                <NumberSelect
                  name="deltaT"
                  title="Delta"
                  step={0.1}
                  min={0}
                  max={5}
                  value={settings.deltaT}
                  setValues={setSettings}
                />
              </>
            }
          />
          <Route
            path="/off"
            element={<SettingsEmpty text="The Climate control is off" />}
          />
          <Route
            path="schedule"
            element={
              <>
                <TallNumberSelect
                  name="minT"
                  title="Min temp."
                  max={35}
                  className="small"
                  value={settings.minT}
                  setValues={setSettings}
                />
                <TallNumberSelect
                  name="maxT"
                  title="Max temp."
                  max={35}
                  className="small"
                  value={settings.maxT}
                  setValues={setSettings}
                />
                <NumberSelect
                  name="deltaT"
                  title="Delta"
                  step={0.1}
                  min={0}
                  max={5}
                  value={settings.deltaT}
                  setValues={setSettings}
                />
                <div className="width100">
                  {settings.intervals.map(
                    ({
                      id,
                      startHour,
                      startMinute,
                      endHour,
                      endMinute,
                      week,
                      working,
                    }) => {
                      return (
                        <WeekInterval
                          key={id}
                          id={id}
                          startHour={startHour}
                          startMinute={startMinute}
                          endHour={endHour}
                          endMinute={endMinute}
                          week={week}
                          working={working}
                          setValues={setSettings}
                        />
                      );
                    }
                  )}
                </div>
              </>
            }
          />
        </Route>
      </Routes>
    </div>
  );
};

export default Settings;
