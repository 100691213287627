import './App.css';

import Boiler from './routes/boiler/boiler.component';

const App = () => {
  return (
    <div className="App">
      <Boiler />
    </div>
  );
};

export default App;
