import { useState, useEffect } from 'react';

import './stats.styles.scss';

import StatItem from '../stat-item/stat-item.component';


const Stats = ({stats}) => {

  return (
    <>
      <div className="stats">
        {stats.sensors.map((sensor) => (
          <StatItem
            key={sensor.address}
            value={
              typeof sensor.temperatureC === 'number'
                ? Math.round(sensor.temperatureC * 100) / 100 + '°C'
                : sensor.temperatureC
            }
            title={sensor.location}
          />
        ))}
        <StatItem value={stats.heating ? 'ON' : 'OFF'} title={'Heating'} />
        <StatItem
          value={
            typeof stats.neededT === 'number'
              ? stats.neededT + '°C'
              : stats.neededT
          }
          title={'Needed temp.'}
        />
      </div>
    </>
  );
};

export default Stats;
