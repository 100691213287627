import { Outlet } from 'react-router-dom';

import './bottom-menu.styles.scss';

const BottomMenu = ({ children }) => {
  return (
    <>
      <div className="bottom-menu">{children}</div>
      <Outlet />
    </>
  );
};

export default BottomMenu;
