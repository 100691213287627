import './stat-item.styles.scss';

const StatItem = ({ value, title }) => {
  return (
    <div className="stat-item">
      <div className="stat-item__value">{value}</div>
      <div className="stat-item__title">{title}</div>
    </div>
  );
};

export default StatItem;
