import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/button/button.component';
import HeaderBar from '../../components/header-bar/header-bar.component';
import Stats from '../../components/stats/stats.component';
import Settings from '../settings/settings.component';

const defaultSettings = {
  intervals: [],
  manner: '',
  minT: 0,
  maxT: 0,
  forceT: 0,
  deltaT: 0.5,
};

const defaultStats = {
  sensors: [
    {
      address: 'address1',
      location: 'Kitchen',
      temperatureC: '...',
    },
    {
      address: 'address2',
      location: 'Bedroom',
      temperatureC: '...',
    },
  ],
  heating: false,
  neededT: '...',
};

let currentSettings = defaultSettings;
const Boiler = () => {
  const [settings, setSettings] = useState(defaultSettings);
  const [stats, setStats] = useState(defaultStats);
  const navigate = useNavigate();

  const fetchStats = async () => {
    const res = await fetch('/boiler/stats');
    const data = await res.json();
    setStats(data);
  };
  const fetchSettings = async () => {
    const res = await fetch('/boiler/settings');
    const data = await res.json();
    currentSettings = data.settings;
    setSettings(structuredClone(currentSettings));
  };

  const onSave = async () => {
    if (
      JSON.stringify({ ...currentSettings, intervals: undefined }) !=
      JSON.stringify({ ...settings, intervals: undefined })
    ) {
      const data = new FormData();
      data.append('manner', settings.manner);
      data.append('minT', settings.minT);
      data.append('maxT', settings.maxT);
      data.append('forceT', settings.forceT);
      data.append('deltaT', settings.deltaT);

      await fetch('/boiler/settings', {
        method: 'POST',
        body: data,
      });
    }

    if (
      JSON.stringify(currentSettings.intervals) !=
      JSON.stringify(settings.intervals)
    ) {
      settings.intervals.forEach(async (interval, index) => {
        if (
          JSON.stringify(interval) !=
          JSON.stringify(currentSettings.intervals[index])
        ) {
          const data = new FormData();
          data.append('id', interval.id);
          data.append('working', interval.working ? 'on' : '');
          data.append('week', interval.week);
          data.append('startHour', interval.startHour);
          data.append('startMinute', interval.startMinute);
          data.append('endHour', interval.endHour);
          data.append('endMinute', interval.endMinute);

          await fetch('http://192.168.0.30/settings/intervals', {
            method: 'POST',
            body: data,
          });
        }
      });
    }

    fetchStats();
    fetchSettings();
  };

  useEffect(() => {
    fetchStats();
    fetchSettings();
  }, []);

  useEffect(() => {
    navigate(`/${settings.manner}`);
  }, [settings.manner]);

  return (
    <>
      <HeaderBar>
        <div>
          <Button
            title={'Refresh'}
            onClick={() => {
              fetchStats();
              fetchSettings();
            }}
          />
        </div>
        <div>Climate control</div>
        <div>
          {JSON.stringify(currentSettings) != JSON.stringify(settings) ? (
            <Button
              title={'Save'}
              onClick={() => {
                onSave();
              }}
            />
          ) : null}
        </div>
      </HeaderBar>
      <Stats stats={stats} />
      <Settings settings={settings} setSettings={setSettings} />
    </>
  );
};

export default Boiler;
