import './bottom-menu-item.styles.scss';

const BottomMenuItem = ({ Icon, title, setValues, active=false }) => {
  const setValue = () => {
    setValues((prevValues) => ({ ...prevValues, manner: title }));
  };
  return (
    <button onClick={setValue} className={`bottom-menu__item ${active && 'active'}`}>
      <Icon className="bottom-menu__svg" />
      <span className="bottom-menu__title">{title}</span>
    </button>
  );
};

export default BottomMenuItem;
