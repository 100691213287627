import './settings-empty.styles.scss';

const SettingsEmpty = ({ text }) => {
  return (
    <div className="settings-empty">
      <div>{text}</div>
    </div>
  );
};

export default SettingsEmpty;
