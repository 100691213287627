import './number-input.styles.scss';

import { ReactComponent as Minus } from '../../assets/minus.svg';
import { ReactComponent as Plus } from '../../assets/plus.svg';

const NumberSelect = ({
  name,
  title,
  value = 0,
  setValues,
  min = -99.99,
  max = 99.99,
  step = 0.5,
}) => {
  const setValue = (value) => {
    return setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const onInput = (event) => {
    let input = event.target.value;
    const regexNumber = /^(?:-?\d{1,2}(?:[.,]\d{1,2})?[.,]?|-?)$/;
    if (!regexNumber.test(input)) return;
    if (
      input === '' ||
      input === '-' ||
      input.slice(-1) === '.' ||
      input.slice(-1) === ','
    ) {
      setValue(input);
    } else {
      input = input.replace(',', '.');
      input = parseFloat(input);
      setValue(input);
    }
  };

  const offInput = () => {
    if (typeof value != 'number') {
      if (value === '' || value === '-') return setValue(0);
      if (value.slice(-1) === '.' || value.slice(-1) === ',')
        setValue(Number(value.slice(0, -1)));
      value.slice(0, -1);
    }
    if (value > max) setValue(max);
    if (value < min) setValue(min);
  };

  const fieldWidth = () => {
    let width = String(value).length;
    if (String(value).includes('.') || String(value).includes(','))
      width -= 0.5;
    if (String(value).includes('-')) width -= 0.5;
    return width;
  };

  return (
    <div className="number-input">
      <div className="number-input__title">{title}</div>
      <button
        onClick={() => {
          let newValue = Math.round((value - step) * 100) / 100;
          if (newValue < min) newValue = min;
          setValue(newValue);
        }}
      >
        <Minus />
      </button>
      <label htmlFor={name} className="number-input__container">
        <input
          className="number-input__input"
          type="text"
          onChange={onInput}
          onBlur={offInput}
          name={name}
          id={name}
          value={value}
          style={{
            width: `${fieldWidth()}ch`,
          }}
        />
        <span>°C</span>
      </label>
      <button
        onClick={() => {
          let newValue = Math.round((value + step) * 100) / 100;
          if (newValue > max) newValue = max;
          setValue(newValue);
        }}
      >
        <Plus />
      </button>
    </div>
  );
};

export default NumberSelect;
